import { createSlice } from "@reduxjs/toolkit";

const enterpriseServiceSlice = createSlice({
    name: 'services',
    initialState: { services: [],selectedService:'',loader:true },
    reducers: {
        setService(state, action) {
            state.services = action.payload;
        },
        setSelectedService(state,action){
            state.selectedService = action.payload;
        }
       
      
       
    },
});

export const enterpriseServiceActions = enterpriseServiceSlice.actions;

export default enterpriseServiceSlice;