//export const API_URL = 'http://localhost:3010';
export const API_URL = 'https://svioxrm.acuizen.com';

export const LOGIN_URL = API_URL + '/users/login';
export const USER_MEL = API_URL + '/users/me';
export const CREATE_TENANTS = API_URL + '/tenants';
export const ALL_TENANTS = API_URL + '/tenants'
export const LOCATIONS_URL = API_URL + '/locations';
export const ENTERPRISEDETAILS = API_URL + '/enterprises_details'
export const TIER1_URL = API_URL + '/tier-ones';
export const MEETING_URL = API_URL + '/meetings';
export const ACTIVITIES_URL = API_URL + '/meetings';
export const TODO_URL = API_URL + '/todo-api';
export const USERS_OWNER_URL = API_URL + '/users';
export const USERS_CREW_URL = API_URL + '/user-crew';
export const USERS_SIGNUP_CREW_URL = API_URL + '/users/signup-crew';
export const VESSEL_URL = API_URL + '/vessels';
export const USER_REGISTER_URL = API_URL + '/users/signup';
export const ASSIGNED_URL = API_URL + '/assigned-api';
export const SUBMIT_URL = API_URL + '/submit-api';
export const CHECKLIST_URL = API_URL + '/checklists';
export const DOCUMENTS_URL = API_URL + '/documents';
export const USER_MEETING_URL = API_URL + '/meetings'
export const RISK_UPDATES = API_URL + '/risk-updates'
export const USER_RISK_URL = API_URL + '/risk-assessment-version-twos'
export const FILE_URL = API_URL + '/files';
export const TEST_CASE_URL = API_URL + '/test-cases';
export const NEW_TEST_CASE_URL = API_URL + '/new-test-cases';
export const MOVE_URL = API_URL + '/movement';
export const TITTLE_CONFIG = API_URL + '/title-configs'
export const HAZARDS_CATEGOTY = 'https://risk-api.acuizen.com/hazards-categories'
export const HAZARDS = 'https://risk-api.acuizen.com/hazards'
export const ASSIGNED_UNIT_URL = API_URL + '/add-ku-user-threes'
export const ASSIGNED_CHECKLIST_URL = API_URL + '/add-checklist-user'
export const GROUP_ASSIGNED_UNIT_URL = API_URL + '/add-ku-group-user-threes'
export const GROUP_ASSIGNED_CHECKLIST_URL = API_URL + '/add-checklist-group-user'
export const ASSIGNED_DOCUMENT_URL = API_URL + '/add-document-user'
export const GROUP_ASSIGNED_DOCUMENT_URL = API_URL + '/add-document-group-user'
export const ASSIGNED_FORM_URL = API_URL + '/add-form-user'
export const GROUP_ASSIGNED_FORM_URL = API_URL + '/add-form-group-user'
export const CURATOR_ASSIGNED_AREA_URL = API_URL + '/add-ka-curator-ones'
export const CREATE_STEP_TITLE = API_URL + '/step-titles'
export const ASSIGN_USER_GROUP = API_URL + '/group-users-add';
export const ASSIGNED_CURATOR_AREA_BY_USER_ID = API_URL + '/curator-list-ones'
export const DASHBOARD_ADMIN_DATA = API_URL + '/dashboard-data'
export const FORGETPASSWORD = API_URL + '/reset-password/init'
export const FORGETPASSWORD_FINISH = API_URL + '/reset-password/finish'
export const COMPANY_LIST = API_URL + '/enterprises'
export const SERVICE_LIST = API_URL + '/services'
export const COMPANY_ADD = API_URL + '/enterprise/add'
export const ENTERPRISE_AND_SERVICE = API_URL + '/enterprise-services'
export const SERVICE_CLONE = API_URL + '/services-clone'
export const DOCUMENT_CATEGORY = API_URL + '/document-categories';

export const SERVICE_SYNC =API_URL +'/syncServices';

export const DROPDOWN_SYNC =API_URL +'/syncDropdowns';

export const ENTERPRISE_DOCUMENT_CATEGORY = (id) => {
    return API_URL + '/enterprises/' + id + '/document-categories';
}

export const UPDATE_TENANTS = (id) => {
    return API_URL + '/tenants/' + id;
}

export const FILE_UPLOAD_TENANTS = (id) => {
    return API_URL + '/files/' + id;
}

export const FILE_DOWNLOAD = (file) => {
    return API_URL + '/files/' + file + '/presigned-url'
}
export const DOCUMENT_CATEGORY_WITH_ID = (id) => {
    return API_URL + '/document-categories/' + id;;
}
export const ENTERPRISE_USER_OWNER_URL = (id) => {

    return API_URL + '/enterprises/' + id + '/ra_member';
}
export const ENTERPRISE_ID_URL = (id) => {
    return API_URL + '/enterprises/' + id;
}
// export const DELETE_SERVICE_ENTERPRISE = API_URL +'/enterprise-services-delete'
export const DELETE_SERVICE_ENTERPRISE = (id, id1) => {
    return API_URL + '/enterprise-services-delete/' + id + '/' + id1;
}
export const ENTERPRISE_DEPARTMENT_URL = (id) => {
    return API_URL + '/enterprises/' + id + '/departments';
}
export const ENTERPRISE_RAROLES_URL = (id) => {
    return API_URL + '/enterprises/' + id + '/ra-roles';
}
export const EDIT_RAROLES_URL = (id) => {
    return API_URL + '/ra-roles/' + id;
}

export const DEPARTMENT_ACTIVITY_URL = (id) => {
    return API_URL + '/departments/' + id + '/work-activities';
}
export const GET_ACCESS_RIGHT_BY_RAROLES_ID = (id) => {
    return API_URL + '/ra-roles/' + id + '/access-rights';
}
export const RAROLE_ACCESS_RIGHT_SAVE = API_URL + '/role-access-rights-all';
export const GET_SERVICE_ENTERPRISE = (id) => {
    return API_URL + '/tenants/' + id + '/services';
}
export const GET_SERVICE_ACCESS = (id) => {
    return API_URL + '/services/' + id + '/dropdowns';
}
export const GET_DROPDOWN = API_URL + '/dropdowns';

export const DROPDOWN_PARENT_ID_DROPDOWNITEM = (id) => {
    return API_URL + '/dropdowns-items-parent/' + id +'/dropdown-items';
}

export const DROPDOWN_ID_DROPDOWNITEM = (id) => {
    return API_URL + '/dropdowns/' + id +'/dropdown-items';
}

export const DROPDOWNITEM_WITH_ID = (id) => {
    return API_URL + '/dropdown-items/' + id ;
}
export const DELETE_SERVICE_ACCESS = (id, id1) => {
    return API_URL + '/tenant-services/' + id + '/' + id1;
}

export const TENANT_SERVICE = API_URL + '/tenant-services'


export const GET_SERVICE_ROLES = (id) => {
    return API_URL + '/services/' + id + '/roles';
}
export const GET_ACCESS_WITH_ID = (id) => {
    return API_URL + '/dropdowns/' + id;
}
export const GET_ROLE_WITH_ID = (id) => {
    return API_URL + '/roles/' + id;
}
export const SERVICES_WITH_ID = (id) => {
    return API_URL + '/services/' + id;
}
// export const NESTED_SUBTOPIC_URL = '/sub-topics';

// export const STANDARDS_URL = API_URL + '/standards';

// export const QUESTIONS_URL = API_URL + '/questions';
// export const SUBQUESTIONS_URL = API_URL + '/sub-questions';
// export const NESTED_SUBQUESTIONS_URL = '/sub-questions';

// export const SURVEYS_URL = API_URL + '/surveys';

// export const STAKEHOLDERS_URL = API_URL + '/stake-holders';
export const KNOWLEDGE_SESSION_USER = (id) => {
    return API_URL + '/knowledge-session-userid/' + id
}
export const KNOWLEDGE_SESSION_KU = (id) => {
    return API_URL + '/knowledge-session-threeid/' + id
}
export const ENTERPRISE_UPDATE_ID_URL = (id, id1) => {
    return API_URL + '/enterprise_update/' + id;
}
export const ENTERPRISE_DELETE_ID_URL = (id) => {
    return API_URL + '/tenants/' + id;
}
export const GROUP_USERS_WITH_ID = (id) => {
    return API_URL + '/group-user-group/' + id;
}
export const DOCUMENTS_WITH_ID_URL = (id) => {
    return API_URL + '/documents/' + id;
}
export const CHANGE_PASSWORD_ID_URL = (id) => {
    return API_URL + '/changepassword/' + id;
}
export const STEP_WITH_ID_URL = (id) => {
    return API_URL + '/step-titles/' + id;
}
export const STEP_TITLE_BY_ID = (id) => {
    return API_URL + '/get-step-titles/' + id;
}
export const STEP_BY_ID = (id) => {
    return API_URL + '/step-titles/' + id;
}
export const ASSIGNED_AREA_BY_id = (id) => {
    return API_URL + '/user-tier-threes-userid/' + id;
}
export const ASSIGNED_CHECKLIST_BY_ID = (id) => {
    return API_URL + '/user-checklist-userid/' + id;
}
export const ASSIGNED_DOCUMENT_BY_ID = (id) => {
    return API_URL + '/user-document-userid/' + id;
}
export const ASSIGNED_FORM_BY_ID = (id) => {
    return API_URL + '/user-form-userid/' + id;
}

export const GROUP_ASSIGNED_AREA_BY_id = (id) => {
    return API_URL + '/group-tier-threes-groupid/' + id;
}
export const GROUP_ASSIGNED_CHECKLIST_BY_ID = (id) => {
    return API_URL + '/group-checklist-groupid/' + id;
}
export const GROUP_ASSIGNED_FORM_BY_ID = (id) => {
    return API_URL + '/group-form-groupid/' + id;
}
export const GROUP_ASSIGNED_DOCUMENT_BY_ID = (id) => {
    return API_URL + '/group-document-groupid/' + id;
}
export const ASSIGNED_CURATOR_AREA_BY_id = (id) => {
    return API_URL + '/curator-tier-ones-userid/' + id;
}
export const HAZARDS_WITH_ID_URL = (id) => {
    return 'https://risk-api.acuizen.com/hazards/' + id;
}
export const USER_WITH_ID_URL = (id) => {
    return API_URL + '/users/' + id;
}

export const SUBMIT_WITH_ID_URL = (id) => {
    return API_URL + '/submit-api/' + id;
}

export const TEST_CASE_WITH_ID_URL = (id) => {
    return API_URL + '/test-cases/' + id;
}
export const USER_ENTERPRISE_URL = (id) => {
    return API_URL + '/users/' + id + '/enterprises';
}
export const ENTERPRISE_TITLE_URL = (id) => {
    return API_URL + '/enterprises/' + id + '/title-config';
}
export const ENTERPRISE_USER_URL = (id) => {
    return API_URL + '/enterprises/' + id + '/users';
}
export const ENTERPRISE_CHECKLIST_URL = (id) => {
    return API_URL + '/enterprises/' + id + '/checklists';
}
export const ENTERPRISE_DOCUMENT_URL = (id) => {
    return API_URL + '/enterprises/' + id + '/documents';
}
export const ENTERPRISE_GROUP_URL = (id) => {
    return API_URL + '/enterprises/' + id + '/groups';
}
export const ENTERPRISE_FORM_URL = (id) => {
    return API_URL + '/enterprises/' + id + '/forms';
}
export const ENTERPRISE_TIER1_URL = (id) => {
    return API_URL + '/enterprises/' + id + '/tier-ones';
}
export const BROADCAST_LIST = (id) => {
    return API_URL + '/enterprises/' + id + '/broadcasts';
}
export const NEW_TEST_CASE_WITH_ID_URL = (id) => {
    return API_URL + '/new-test-cases/' + id;
}
export const RISK_UPDATE_WITH_ID_URL = (id) => {
    return API_URL + '/risk-assessments/' + id + '/risk-updates';
}
export const HAZARD_WITH_ID_URL = (id) => {
    return 'https://risk-api.acuizen.com/hazards-categories/' + id + '/hazards';
}
export const HAZARD_CATEGOTY_WITH_ID_URL = (id) => {
    return 'https://risk-api.acuizen.com/hazards-categories/' + id;
}
export const CHECKLIST_WITH_ID_URL = (id) => {
    return API_URL + '/checklists/' + id;
}
export const FORM_WITH_ID_URL = (id) => {
    return API_URL + '/forms/' + id;
}
export const GROUP_WITH_ID_URL = (id) => {
    return API_URL + '/groups/' + id;
}
export const BROADCAST_WITH_ID_URL = (id) => {
    return API_URL + '/broadcasts/' + id;
}
export const VESSEL_WITH_ID_URL = (id) => {
    return API_URL + '/vessels/' + id;
}
export const MEETING_WITH_ID_URL = (id) => {
    return API_URL + '/meetings/' + id;
}

export const RISK_WITH_ID_URL = (id) => {
    return API_URL + '/risk-assessments/' + id;
}
export const CREW_WITH_ID_URL = (id) => {
    return API_URL + '/user-crew/' + id;
}

export const LOCATION_WITH_ID_URL = (id) => {
    return API_URL + '/locations/' + id;
}

export const ASSINGED_TEST_CASE_URL = (id) => {
    return API_URL + '/assign-test-cases/' + id;
}


export const DOWNLOAD_DOCS_URL = (files) => {
    return API_URL + '/docs/' + files;
}
export const USER_WITH_ID = (id) => {
    return API_URL + '/users/' + id;
}
export const LOCATION_CONFIG_URL = (id) => {
    return API_URL + '/locations/' + id + '/config';
}
export const VESSELS_MEETING_URL = (id) => {
    return API_URL + '/vessels/' + id + '/meetings';
}
export const VESSELS_RISK_URL = (id) => {
    return API_URL + '/vessels/' + id + '/risk-assessment-version-twos';
}
export const VESSELS_USERS_URL = (id) => {
    return API_URL + '/vessels/' + id + '/user';
}

export const LOCATION1_URL = (id) => {
    return API_URL + '/enterprises/' + id + '/location-ones';
}

export const LOCATION_TIER1_TIER2_URL = (id) => {
    return API_URL + '/location-ones/' + id + '/location-twos';
}

export const LOCATION_TIER2_TIER3_URL = (id) => {
    return API_URL + '/location-twos/' + id + '/location-threes';
}
export const LOCATION_TIER3_TIER4_URL = (id) => {
    return API_URL + '/location-threes/' + id + '/location-fours';
}
export const LOCATION_TIER4_TIER5_URL = (id) => {
    return API_URL + '/location-fours/' + id + '/location-fives';
}
export const TIER1_TIER2_URL = (id) => {
    return API_URL + '/tier-ones/' + id + '/tier-twos';
}
export const LOCATION1_WITH_ID_URL = (id) => {
    return API_URL + '/location-ones/' + id;
}

export const TIER2_TIER3_URL = (id) => {
    return API_URL + '/tier-twos/' + id + '/tier-threes';
}

export const TIER3_TIER4_URL = (id) => {
    return API_URL + '/tier-twos/' + id + '/tier-threes';
}

export const TIER4_TIER5_URL = (id) => {
    return API_URL + '/tier-threes/' + id + '/tier-fours';
}
export const ENTERPRISE_USER_RISK_URL = (id) => {
    return API_URL + '/enterprises/' + id + '/risk-assessments';
}
export const USERS_URL_WITH_ID = (id) => {
    return API_URL + '/users/' + id;
}



export const GET_INDIVIDUAL_USER_LOCATION_ROLE_URL = API_URL + '/user-location-roles/get-individual-users';

export const INDIVIDUAL_USER_LOCATION_ROLE_URL = API_URL + '/individual-user-location-roles';

export const EDIT_TIER_URL = (mode, id) => {
    switch (mode) {
        case 'tier1':

            return API_URL + '/tier-ones/' + id;

        case 'tier2':

            return API_URL + '/tier-twos/' + id;

        case 'tier3':

            return API_URL + '/tier-threes/' + id;

        case 'tier4':

            return API_URL + '/tier-threes/' + id;

        default:
            break;
    }
}

export const EDIT_ACTIVITY_URL = (mode, id) => {
    switch (mode) {
        case 'tier1':

            return API_URL + '/departments/' + id;

        case 'tier2':

            return API_URL + '/work-activities/' + id;



        default:
            break;
    }
}
export const EDIT_LOCATION_URL = (mode, id) => {
    switch (mode) {
        case 'tier1':

            return API_URL + '/location-twos/' + id;

        case 'tier2':

            return API_URL + '/location-threes/' + id;

        case 'tier3':

            return API_URL + '/location-fours/' + id;


        default:
            break;
    }
}
export const CLONE_TIER_URL = API_URL + '/tier-threes-clone';

export const REARRANGE_TIER_URL = (mode) => {
    switch (mode) {
        case 'tier1':

            return API_URL + '/tier-ones-rearrange';

        case 'tier2':

            return API_URL + '/tier-twos-rearrange';

        case 'tier3':

            return API_URL + '/tier-threes-rearrange';


        default:
            break;
    }
}