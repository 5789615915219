import { configureStore } from "@reduxjs/toolkit";

import loginSlice from './login-slice'
import userEnterpriseSlice from "./userenterprise-slice";
import enterpriseServiceSlice from "./enterpriseservice-slice"

const store = configureStore({
    reducer: {
        login: loginSlice.reducer,
        details:userEnterpriseSlice.reducer,
        service:enterpriseServiceSlice.reducer
       
    },
});

export default store;