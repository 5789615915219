import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import { USER_ENTERPRISE_URL, USER_MEL, GET_SERVICE_ENTERPRISE } from '../constants';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import API from './services/API';
import { userEnterpriseActions } from '../store/userenterprise-slice'
import { enterpriseServiceActions } from '../store/enterpriseservice-slice'

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
function App() {
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);
  const dispatch = useDispatch()
  const loader = useSelector((state) => state.details.loader)
  const user = useSelector((state) => state.details.user)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem('access_token') ? true : false;
      if (token) {
        if (localStorage.getItem('access_token')) {
          const response = await API.get(USER_MEL);
          if (response.status === 200) {
            dispatch(userEnterpriseActions.setUvalue(response.data));
                
          }
        }else{
          
        }
      }
    }

    fetchData();
  }, [localStorage.getItem('access_token')]);

  let navbarComponent = !isFullPageLayout  ? <Navbar /> : '';
  let sidebarComponent = !isFullPageLayout ? <Sidebar /> : '';
  let footerComponent = !isFullPageLayout ? <Footer /> : '';



  useEffect(() => {
    onRouteChanged();
  }, [location.pathname]);

  const onRouteChanged = () => {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/','/login','/enlogin', '/forgetpassword', '/user-pages/login-2', '/user-pages/register-1', '/user-pages/register-2', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500'];

    if (fullPageLayoutRoutes.includes(location.pathname)) {
      setIsFullPageLayout(true);
      document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');

    } else {
      setIsFullPageLayout(false);
      document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
    }

  }

  return (
    <div className="container-scroller">
      {navbarComponent}
      <div className="container-fluid page-body-wrapper">
        {sidebarComponent}
        <div className="main-panel">
          <div className="content-wrapper">
            {!loader ?
              <AppRoutes user={user} data={true} /> :
              <AppRoutes data={false} />
            }

          </div>
          {footerComponent}
        </div>
      </div>
    </div>
  );

}


export default App;
