import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Buffer } from 'buffer';
import { enterpriseServiceActions } from '../../store/enterpriseservice-slice'
window.Buffer = Buffer;
class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/reports', state: 'reportElementsMenuOpen' },
      { path: '/users', state: 'usersMenuOpen' },
      { path: '/config', state: 'configMenuOpen' },
      { path: '/appli', state: 'appliMenuOpen' },
      { path: '/basic-ui', state: 'basicUiMenuOpen' },
      { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
      { path: '/broadcast', state: 'formElementsMenuOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/maps', state: 'mapsMenuOpen' },
      { path: '/editors', state: 'editorsMenuOpen' },
      { path: '/icons', state: 'iconsMenuOpen' },
      { path: '/charts', state: 'chartsMenuOpen' },
      { path: '/user-pages', state: 'userPagesMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/general-pages', state: 'generalPagesMenuOpen' },
      { path: '/ecommerce', state: 'ecommercePagesMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }
  encodeUserEmail = (userEmail) => {
    const base64Encoded = Buffer.from(userEmail).toString('base64');
    const replacedChars = base64Encoded
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
    const trimmed = replacedChars.replace(/=+$/, '');

    return trimmed;
  };
  render() {



    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar"  >

        <ul className="nav">



          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-home menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>
          <li className={this.isPathActive('/company') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/company">
              <i className="mdi mdi-account-multiple-plus menu-icon"></i>
              <span className="menu-title">Enterprises</span>
            </Link>
          </li>
          <li className={this.isPathActive('/services') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/services">
              <i className="mdi  mdi-briefcase-check menu-icon"></i>
              <span className="menu-title">Modules</span>
            </Link>
          </li>
          <li className={this.isPathActive('/dropdowns') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dropdowns">
              <i className="mdi mdi-alert-outline menu-icon"></i>
              <span className="menu-title">General DropDowns</span>
            </Link>
          </li>
          <li className={this.isPathActive('/appli') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.appliMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('appliMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-alert-outline menu-icon" > </i>
              <span className="menu-title">Repositories</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.appliMenuOpen}>
              <ul className="nav flex-column sub-menu">

                <li className="nav-item" > <Link className={this.isPathActive('/appli/hazards') ? 'nav-link active' : 'nav-link'} to="/appli/hazards">Hazards</Link></li>
                <li className="nav-item" > <Link className={this.isPathActive('/appli/hazards') ? 'nav-link active' : 'nav-link'} to="/appli/hazards">Icon  Library</Link></li>
                <li className="nav-item" > <Link className={this.isPathActive('/appli/hazards') ? 'nav-link active' : 'nav-link'} to="/appli/hazards">Email Template</Link></li>
              </ul>
            </Collapse>
          </li>









        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (id) => dispatch(enterpriseServiceActions.setSelectedService(id))
  }
};
const mapStateToProps = state => {
  return {
    data: state.details,
    service: state.service
  }

}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));